import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/focal-length-f-stop",
  "date": "2014-10-11",
  "title": "FOCAL LENGTH AND F-STOP",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "Very simply, focal-length is the distance from the lens to the film, when focused on a subject at infinity. In other words, focal length equals image distance for a far subject. To focus on something closer than infinity, the lens is moved farther away from the film or sensor. This is why most lenses get longer when you turn the focusing ring."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The distances follow this formula:`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.paragon-press.com/lens/formula.gif",
        "alt": "The distances"
      }}></img></p>
    <p>{`This means a 400mm lens should be 400mm long. If you get out your ruler and measure it, you will find it is less than 400mm. That is because a camera lens
has many individual glass lenses inside, and this makes it behave as if it is longer than it is. This is called `}<strong parentName="p">{`telephoto`}</strong>{`.`}</p>
    <h2>{`What is F-Stop?`}</h2>
    <p>{`F-stop is the focal length divided by the diameter of the lens. For example, a 200mm f/4 lens will be 50mm wide. Get out your ruler and measure it. 200mm/50mm = f/4.
That is why f-stop is typically written as `}<strong parentName="p">{`F/4`}</strong>{`, meaning `}<strong parentName="p">{`focal-length over 4`}</strong>{` or `}<strong parentName="p">{`focal-length divided by four`}</strong>{`.`}</p>
    <h2>{`Where do those numbers come from?`}</h2>
    <p>{`Lenses are marked with a series off-stops, each one lets in half as much light as the previous one. The light-gathering ability of a lens is determined by its area, and f-stops are determined by diameter.
Area is related to diameter squared. The progression of f-stops, 1 - 1.4 - 2 - 2.8 - 4 - 5.6 - 8 - 11 - 16 - 22 - 32, are powers of the square root of 2.`}</p>
    <h2>{`Lens Focal Length Chart`}</h2>
    <p>{`Taking the nature photographs of wildflowers and wildlife require an assortment of lenses depending on the subject.
Typically normal to wide-angle lenses are used for landscape photos and very long telephotos are used for wildlife.
The photographs below show the views of a single scene taken using these lenses. `}</p>
    <p>{`These eight photos were taken from the same place with different lenses.`}</p>
    <table>
    <tr>
        <td><img src="http://www.paragon-press.com/lens/24mm.jpg" alt="" width="240" height="160" /></td>
        <td width="100">24mm</td>
        <td><img src="http://www.paragon-press.com/lens/35mm.jpg" alt="" width="240" height="160" /></td>
        <td width="100">35mm</td>
    </tr>
    <tr>
        <td><img src="http://www.paragon-press.com/lens/50mm.jpg" alt="" width="240" height="160" /></td>
        <td>50mm</td>
        <td><img src="http://www.paragon-press.com/lens/100mm.jpg" alt="" width="240" height="161" /></td>
        <td>100mm</td>
    </tr>
    <tr>
        <td><img src="http://www.paragon-press.com/lens/200mm.jpg" alt="" width="240" height="160" /></td>
        <td>200mm</td>
        <td><img src="http://www.paragon-press.com/lens/400mm.jpg" alt="" width="240" height="160" /></td>
        <td>400mm</td>
    </tr>
    <tr>
        <td><img src="http://www.paragon-press.com/lens/800mm.jpg" alt="" width="240" height="160" /></td>
        <td>800mm</td>
        <td><img src="http://www.paragon-press.com/lens/1200mm.jpg" alt="" width="240" height="160" /></td>
        <td>1200mm</td>
    </tr>
    </table>
    <h2>{`I want to take a bird picture. What lens should I use?`}</h2>
    <p><img parentName="p" {...{
        "src": "http://www.paragon-press.com/lens/lensmbb.jpg",
        "alt": "a bird picture"
      }}></img></p>
    <p>{`It is a symptom of bird photography that your lenses never seem to be long enough. This is because the birds are so small.
When photographing large animals, a 400mm gives you a decent image size from a reasonable distance. But remember, an elk is six or seven feet tall.
A bird is barely six inches long, so when your subject is twelve times smaller, you have to be twelve times closer.`}</p>
    <p>{`As you can see, even a relatively huge 600mm lens at the relatively close distance of 20 feet doesn't give the huge magnification you might expect.
The field of view is about twelve inches. It takes a big lens to photograph a small bird.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      